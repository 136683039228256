import Graph from "react-graph-vis";
import { useState, useEffect, useRef, useMemo, useContext } from "react";
import io from "socket.io-client";
import React from "react";
import {
  EdgesData,
  HypchartOptions,
  HypeChartEndpoint,
  InitData,
  MaxNodeCount,
} from "../../constants";
import { SearchForm } from "../SearchForm";
import { AppContext } from "../../providers/AppProvider";

const unactive = 0.3,
  active = 0.3;

function randomColor() {
  const red = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  const green = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  const blue = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  return `#${red}${green}${blue}`;
}

const HypeChart = ({ openModal, searchInput }) => {
  const { mainNode } = useContext(AppContext);
  const input = useRef(searchInput);

  const mainNodeRef = useRef(mainNode);

  useEffect(() => {
    mainNodeRef.current = mainNode || null;
  }, [mainNode]);

  const graphData = useRef([...InitData]);
  const edgesData = useRef([...EdgesData]);

  const [state] = useState({
    graph: {
      nodes: [...InitData],
      edges: [...EdgesData],
    },
    events: {
      click: ({ nodes }) => {
        if (nodes) {
          const foundNode = graphData.current.find(
            (graphNode) => graphNode.id === nodes[0]
          );
          if (foundNode && foundNode?.telegram_username && openModal)
            openModal(foundNode);
        }
      },
    },
  });

  const networkRef = useRef(null);

  const socket = io.connect(HypeChartEndpoint);

  const createNode = async (data, callback, helper = true) => {
    const color = randomColor();

    if (networkRef.current) {
      if (data?.edges) {
        await data.edges.forEach(async (edge) => {
          try {
            const fromNode = graphData.current.find(
              (node) => node.id === edge.from
            );
            const toNode = graphData.current.find(
              (node) => node.id === edge.to
            );

            if (input.current) {
              edge.color = {
                opacity: unactive,
                color: "#ffffff",
                hover: "#ffffff",
                highlight: "#ffffff",
              };
            } else {
              edge.color = {
                opacity: active,
                color: "#ffffff",
                hover: "#ffffff",
                highlight: "#ffffff",
              };
            }

            if (fromNode && toNode) {
              if (networkRef.current.body.data.nodes.length < MaxNodeCount) {
                await networkRef.current.body.data.edges.add(edge);
                edgesData.current.push(edge);
              }
            } else {
            }
          } catch (e) {
            // console.log(e);
          }
        });
      }
      if (data?.nodes) {
        await data.nodes.forEach(async (node) => {
          try {
            node.size = node?.sizeB || 20;
            if (node?.image) {
              node.shape = "circularImage";
            }

            if (networkRef.current.body.data.nodes.length >= MaxNodeCount) {
              socket.disconnect();
              console.log("socket disconnect");
            }

            if (networkRef.current.body.data.nodes.length < MaxNodeCount) {
              if (!node?.opacityB) {
                if (input.current) {
                  if (node.msg) {
                    const includesInMessage = node.msg
                      .toLowerCase()
                      .includes(input.current.toString().toLowerCase());
                    const includesInTitle = node.label
                      .toLowerCase()
                      .includes(input.current.toString().toLowerCase());
                    node.opacity =
                      includesInMessage || includesInTitle ? active : unactive;
                  } else {
                    node.opacity = unactive;
                  }
                }
              } else {
                node.opacity = active;
              }

              if (helper && mainNodeRef.current) {
                const isLiquid = edgesData.current.find(
                  (newEdge) =>
                    newEdge.from === mainNodeRef.current.id ||
                    newEdge.to === mainNodeRef.current.id
                );
                if (isLiquid) {
                  await networkRef.current.body.data.nodes.add({
                    ...node,
                    color,
                  });
                }
              } else {
                await networkRef.current.body.data.nodes.add({
                  ...node,
                  color,
                });
              }
              const foundInMainArr = graphData.current.find(
                (main_node) => node.id === main_node.id
              );
              if (!foundInMainArr) graphData.current.push(node);
            }

            if (callback) callback();
          } catch (e) {
            // console.log(e);
          }
        });
      }
    }
  };

  const updateNodeOpacity = (nodeId, opacity) => {
    if (networkRef.current) {
      const node = networkRef.current.body.data.nodes.get(nodeId);
      if (node) {
        networkRef.current.body.data.nodes.update({
          id: nodeId,
          color: {
            border: "#000000",
          },
          opacity,
        });
      }
    }
  };

  const updateNodeOpacityAll = (opacity) => {
    if (networkRef.current) {
      const allNodes = networkRef.current.body.data.nodes.get();
      allNodes.forEach((node) => {
        networkRef.current.body.data.nodes.update({
          id: node.id,
          color: {
            border: "#000000",
          },
          opacity,
        });
      });
    }
  };

  const onResize = () => {
    // if (networkRef.current) networkRef.current.fit();
  };

  const handleFitClick = () => {
    if (networkRef.current) networkRef.current.fit();
  };

  const nodeVisualUpdate = () => {
    if (searchInput) {
      graphData.current.forEach((graphNode) => {
        if (!graphNode.msg) return;
        const includes = graphNode.msg
          .toLowerCase()
          .includes(searchInput.toString().toLowerCase());
        if (includes) {
          updateNodeOpacity(graphNode.id, 1);
        } else {
          updateNodeOpacity(graphNode.id, 0.3);
        }
      });
      networkRef.current.body.data.edges.forEach((element) => {
        networkRef.current.body.data.edges.update({
          id: element.id,
          color: {
            color: "#ffffff",
            hover: "#ffffff",
            highlight: "#ffffff",
            opacity: unactive,
          },
        });
      });
    } else {
      updateNodeOpacityAll(1);
      networkRef.current.body.data.edges.forEach((element) => {
        networkRef.current.body.data.edges.update({
          id: element.id,
          color: {
            color: "#ffffff",
            hover: "#ffffff",
            highlight: "#ffffff",
            opacity: active,
          },
        });
      });
    }
  };

  useMemo(() => {
    socket.on("update_graph", (data) => {
      createNode(data);
    });

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      socket.off("update_graph");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (networkRef.current) {
      networkRef.current.moveTo({
        scale: 0.35,
        position: { x: 0, y: 0 },
      });
    }
  }, [networkRef]);

  useEffect(() => {
    input.current = searchInput;
    nodeVisualUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const { graph, events } = state;

  const getNetwork = (network) => {
    networkRef.current = network;
  };

  const clearNodes = (ids) => {
    if (networkRef.current && networkRef.current.body.data.nodes) {
      networkRef.current.body.data.nodes.clear();
      if (ids) {
        let nodeData = [];
        ids.forEach((id) => {
          const allEdges = JSON.parse(JSON.stringify(edgesData.current));
          const allNodes = JSON.parse(JSON.stringify(graphData.current));

          const edgesWithFoundNodesById = allEdges.filter((edge) => {
            if (edge.from === id || edge.to === id) {
              return true;
            }
            return false;
          });

          const foundNode = allNodes.find((node) => node.id === id);

          foundNode.sizeB = 80;

          const findAllNodesById = allNodes.filter((node) => {
            let found = false;
            for (let i = 0; i < edgesWithFoundNodesById.length; i++) {
              const edge = edgesWithFoundNodesById[i];
              if (
                (node.id === edge.from && node.id !== id) ||
                (node.id === edge.to && node.id !== id)
              ) {
                return true;
              }
            }
            return found;
          });
          const data = [foundNode, ...findAllNodesById];

          nodeData = [...nodeData, ...data];
        });
        // return;

        nodeData = nodeData.map((item) => {
          const foundItem = ids.find((id) => id === item.id);
          if (foundItem) {
            return {
              ...item,
              sizeB: 40,
            };
          }
          return item;
        });

        try {
          createNode(
            {
              nodes: [...nodeData],
            },
            () => {
              updateNodeOpacityAll(1);
              handleFitClick();
            },
            false
          );
        } catch (error) {
          // console.log(error);
        }
      } else {
        const allNodes = JSON.parse(JSON.stringify(graphData.current));
        createNode({ nodes: allNodes });
      }
    }
  };

  const reset = () => {
    networkRef.current.body.data.nodes.clear();
    const allNodes = graphData.current;
    createNode({ nodes: allNodes }, handleFitClick, false);
  };

  return (
    <>
      <SearchForm
        clearNodes={clearNodes}
        reset={reset}
        data={graphData.current || []}
      />
      <Graph
        graph={graph}
        options={HypchartOptions}
        events={events}
        getNetwork={getNetwork}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <button onClick={handleFitClick} className="button fit-button">
        Fit
      </button>
    </>
  );
};

export { HypeChart };
